import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { ReferenceMonthService } from '@services/reference-month.service';
import { ScaleAmountService } from '@services/scale-amount.service';
import { ScaleAmountEvolutionGroupService } from '@services/scale-amount-evolution-group.service';

import { ScalesAmount } from '@classes/parameters/scale-amount/scales-amount';
import { ScaleAmountEvolutionGroup } from '@classes/parameters/scale-amount/scale-amount-evolution-group';

@Injectable({
    providedIn: 'root'
})
export class ScaleAmountResolver  {

    constructor(
        private uiSrv: UiService,
        private refMonthSrv: ReferenceMonthService,
        private scaleAmountSrv: ScaleAmountService,
        private scaleAmountEvolutionGroupSrv: ScaleAmountEvolutionGroupService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ scalesAmount: ScalesAmount, scaleAmountEvolutionGroups: ScaleAmountEvolutionGroup[] }> {
        this.uiSrv.routeLoadingStateChanged.next(true);
        const refMonth = this.refMonthSrv.refMonth;
        const filters = `referenceMonth==${refMonth}`;

        return forkJoin([
            this.scaleAmountSrv.get(1, 15, filters),
            this.scaleAmountEvolutionGroupSrv.get()
        ]).pipe(
            map(([scalesAmountFromSrv, scaleAmountEvolutionGroups]) => {
                const scalesAmount = new ScalesAmount(scalesAmountFromSrv.body, scalesAmountFromSrv.headers.get('x-pagination'));
                this.uiSrv.routeLoadingStateChanged.next(false);
                return { scalesAmount, scaleAmountEvolutionGroups };
            })
        );
    }
}
