import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { ArticleFunctionalService } from '@services/article-functional.service';

import { ArticleFunctional } from '@classes/article/article-functional';

@Injectable()
export class FunctionalArticlesResolver  {
  constructor(
    private uiSrv: UiService,
    private articleFunctionalSrv: ArticleFunctionalService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ArticleFunctional[]> {
    this.uiSrv.routeLoadingStateChanged.next(true);

    return this.articleFunctionalSrv.getFunctional()
      .pipe(
        tap(() => {
          this.uiSrv.routeLoadingStateChanged.next(false);
        })
      );
  }
}
