import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { SubstitutionScalePaymentService } from '@services/substitution-scale-payment.service';
import { ScalePaymentService } from '@services/scale-payment.service';

import { SubstitutionScalePayment } from '@classes/parameters/substitution-scale-payment';
import { Scale } from '@classes/scenario/scale';
import { StrategyService } from '@services/strategy.service';
import { Strategy } from '@classes/scenario/strategy';

@Injectable()
export class SubstitutionScalePaymentResolver  {
    constructor(
        private uiSrv: UiService,
        private substitutionScalePaymentSrv: SubstitutionScalePaymentService,
        private scaleSrv: ScalePaymentService,
        private strategySrv: StrategyService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{substitutionScales: SubstitutionScalePayment[], scales: Scale[], strategies: Strategy[]}> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return forkJoin([
            this.substitutionScalePaymentSrv.get(),
            this.scaleSrv.get(),
            this.strategySrv.get()
        ]).pipe(
            map(([substitutionScales, scales, strategies]) => {
                this.uiSrv.routeLoadingStateChanged.next(false);
                return {substitutionScales, scales, strategies};
            })
        );
    }
}
